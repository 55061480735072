<template>
  <Portal to="modals">
    <Transition name="app-modal-fade" mode="in-out">
      <div v-if="value" class="app-modal">
        <div
          v-if="value"
          ref="appModal"
          v-click-outside="onClickedOutside"
          tabindex="0"
          class="app-modal__content"
          @keydown.esc="onClose"
        >
          <IconCross class="app-modal__close" @click="onClose" />
          <slot />
        </div>
      </div>
    </Transition>
  </Portal>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import IconCross from '@/components/ui/icons/icon-cross.svg'

export default {
  components: {
    IconCross,
  },
  directives: {
    ClickOutside,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.value,
    }
  },
  watch: {
    async value(newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$refs.appModal.focus()
      }

      // click outside is called also on initial modal open and closes modal immediately
      setTimeout(() => {
        this.isVisible = newValue
      }, 0)
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },
    onClickedOutside() {
      this.isVisible && this.onClose()
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.app-modal {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba($color-black, 0.6);

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    overflow: auto;
    max-width: 650px;
    padding: 40px 0 20px;
    z-index: 1000;
    background-color: $color-white;
    box-shadow: 0 4px 10px 0 rgba($color-black, 0.2);
    outline: none;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    box-sizing: content-box;
    padding: 20px;
    right: 0;
    top: 0;
  }
}

.app-modal-fade-enter-active,
.app-modal-fade-leave-active {
  transition-property: opacity;
  transition-duration: 200ms;
}

.app-modal-fade-enter,
.app-modal-fade-leave-to {
  opacity: 0;
}

.app-modal-fade-leave,
.app-modal-fade-enter-to {
  opacity: 1;
}
</style>
