<template>
  <nav
    v-click-outside="hideNav"
    class="header-navigation no-print"
    :aria-expanded="navigation.isActive ? 'true' : 'false'"
  >
    <HeaderDossierItems class="dossiers-desktop" />
    <UserButton />
    <SearchButton />
    <button
      class="header-navigation__button header-navigation__button--mobilenav"
      aria-label="mobilemenu"
      @click="toggleNav"
    >
      <IconMobileNav class="icon-mobile-nav" />
      <IconCross class="icon-cross" />
    </button>
    <button class="header-navigation__button header-navigation__button--nav" aria-label="menu" @click="toggleNav">
      <IconNav class="icon-nav" />
      <IconCross class="icon-cross" />
    </button>
    <Transition name="header-nav-fade">
      <div v-show="navigation.isActive" class="header-navigation-menu">
        <HeaderDossierItems class="dossiers-mobile" />
        <ul role="menu">
          <li class="header-navigation__search-mobile">
            <form id="header-search-mobile" class="header-search-mobile" @submit.prevent="submitSearch">
              <DefaultInput
                v-model="searchTerm"
                :value="searchTerm"
                class="header-search-mobile-input"
                type="search"
                placeholder="Wonach suchen Sie?"
              />
              <button
                type="submit"
                form="header-search-mobile"
                value="submit search"
                class="header-search-mobile-trigger"
              >
                <span class="visually-hidden">Search Term</span>
                <IconSearch class="_icon" />
              </button>
            </form>
          </li>
          <li v-for="(navItem, index) in headerNavigation" :key="index" role="menuitem">
            <ArticleLink
              v-if="navItem.attributes.link.type === 'ContentLink' && navItem.attributes.link.relationships.article"
              :article="navItem.attributes.link.relationships.article"
              class="header-navigation-menu__link"
            >
              {{ navItem.attributes.link.attributes.text }}
            </ArticleLink>
            <NavigationLink
              v-else-if="navItem.attributes.link.type === 'ChannelLink'"
              :channel="navItem.attributes.link.relationships.channel"
              class="header-navigation-menu__link"
            >
              {{ navItem.attributes.link.attributes.text }}
            </NavigationLink>
            <a
              v-else-if="navItem.attributes.link.attributes.url"
              :href="navItem.attributes.link.attributes.url"
              :target="navItem.attributes.link.attributes.target"
              class="header-navigation-menu__link"
            >
              {{ navItem.attributes.link.attributes.text }}
            </a>
          </li>
        </ul>
      </div>
    </Transition>
  </nav>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import SearchButton from './search-button'
import UserButton from './user-button'
import HeaderDossierItems from '@/components/dossier/dossier-items'

import NavigationLink from '@/components/ui/page/navigation-link'
import ArticleLink from '@/components/article/link'
import DefaultInput from '@/components/ui/form/input/default'

import IconMobileNav from '@/components/ui/icons/icon-mobile-nav.svg'
import IconNav from '@/components/ui/icons/icon-nav.svg'
import IconCross from '@/components/ui/icons/icon-cross.svg'
import IconSearch from '@/components/ui/icons/icon-search.svg'

export default {
  components: {
    NavigationLink,
    ArticleLink,
    DefaultInput,
    UserButton,
    IconMobileNav,
    IconNav,
    IconCross,
    IconSearch,
    HeaderDossierItems,
    SearchButton,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      navigation: {
        isActive: false,
      },
      searchTerm: null,
    }
  },
  computed: {
    headerNavigation() {
      return this.$store.getters['navigation/headerNavigation']
    },
  },
  created() {
    this.$nuxt.$on('openedUserNav', this.closeNav)
  },
  methods: {
    toggleNav() {
      this.navigation.isActive = !this.navigation.isActive
      if (this.navigation.isActive) {
        this.$nuxt.$emit('openedMainNav')
      }
    },
    closeNav() {
      this.navigation.isActive = false
    },
    hideNav() {
      this.navigation.isActive = false
    },
    submitSearch() {
      // eslint-disable-next-line no-console
      console.log(this.searchTerm)
      this.$router.push({
        path: '/suche',
        query: { q: this.searchTerm },
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';
@import '../../../css/import/mixins.scss';

.header-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;

  @media all and (min-width: $screen-width-767) {
    position: relative;
    right: auto;
  }

  @media screen and (min-width: $screen-width-1023) {
    display: flex;
    align-items: center;
    width: calc(100% - 340px);
    justify-content: flex-end;
  }

  &__button {
    background-color: transparent;
    margin: 0 5px;

    &--active {
      svg,
      path,
      text {
        fill: $color-turquoise;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--mobilenav {
      @media all and (min-width: $screen-width-767) {
        display: none;
      }
    }

    &--search {
      display: none;

      @media all and (min-width: $screen-width-767) {
        display: block;
      }
    }

    &--nav {
      display: none;
      @media all and (min-width: $screen-width-767) {
        display: block;
      }
    }
  }

  &__search-mobile {
    padding: 0;
    margin: 10px 0;
    position: relative;

    input {
      margin: 0;
      padding: 15px;
      font-size: 16px;
    }

    @media all and (min-width: $screen-width-767) {
      display: none;
    }

    &:before {
      display: none;
    }

    &-icon {
      position: absolute;
      right: 15px;
      top: 18px;

      path {
        fill: $color-line-gray !important;
      }
    }
  }

  .header-navigation-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 5;
    width: calc(100% - 10px);
    max-width: 694px;
    background-color: $color-white;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.4);

    @media screen and (min-width: $screen-width-767) {
      max-width: 380px;
    }

    &__link {
      position: relative;
      width: 100%;
      padding: 1rem 0;
      border-bottom: 1px solid $color-light-grey;
      margin-right: 0;
      font-size: 18px;
      color: $color-text;
      text-decoration: none;

      @media screen and (min-width: $screen-width-767) {
        padding: 1.25rem 0;
      }
    }

    ul[role='menu'] {
      width: 100%;
      margin: 0;
      padding: 1rem 1rem 2rem;
      list-style-type: none;

      li[role='menuitem'] {
        display: flex;
        align-items: center;
        padding: 0;
        line-height: 1;
        margin-bottom: 0;

        &:hover a {
          border-bottom: 1px solid $color-turquoise;
          color: $color-turquoise;
          transition-duration: 300s;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  .icon-cross {
    display: none;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .header-navigation__button--mobilenav .icon-cross {
    margin-bottom: 5px;
    margin-left: 1px;
    margin-right: 3px;
  }

  &[aria-expanded='true'] .icon-nav,
  &[aria-expanded='true'] .icon-mobile-nav {
    display: none;
  }

  &[aria-expanded='true'] .icon-cross {
    display: block;
  }
}

.header-nav-fade-enter-active,
.header-nav-fade-leave-active {
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.header-nav-fade-enter,
.header-nav-fade-leave-to {
  opacity: 0;
  transform: translate3d(-6px, 0, 0);
}

.header-nav-fade-leave,
.header-nav-fade-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.header-search-mobile {
  position: relative;
  display: block;

  @media all and (min-width: $screen-width-767) {
    display: none;
  }
}

.header-search-mobile-input {
  &:active,
  &:focus {
    border: 1px solid $color-turquoise;
    transition-duration: 0.3s;
  }
}

.header-search-mobile-trigger {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 42px;
  height: 52px;
  background-color: $transparent;

  ._icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    path {
      fill: $color-text-gray !important;
    }
  }
}
</style>
