<template>
  <div v-if="tinypassActive" v-click-outside="hideNav" class="header-navigation--user" @click="trackingEventsPush">
    <button
      class="header-navigation__button"
      :class="{ 'header-navigation__button--active': isLoggedIn }"
      aria-label="toggle user menu"
      @click="() => (isLoggedIn ? toggleMenu() : showLogin())"
    >
      <IconUser />
    </button>
    <Transition name="user-menu-fade">
      <nav v-show="isLoggedIn && isNavOpened" :aria-expanded="isNavOpened ? 'true' : 'false'" class="header-user-menu">
        <ul class="user-menu-list" role="menu">
          <li role="menuitem">
            <NuxtLink class="user-menu-link" :to="{ name: 'benutzerkonto' }"> Benutzerkonto </NuxtLink>
          </li>
          <li role="menuitem">
            <a href="#" class="user-menu-link" @click.prevent="$store.dispatch('tinypass/logout')"> Logout </a>
          </li>
        </ul>
      </nav>
    </Transition>
    <AppModal v-model="isConsentDialogOpen">
      <ConsentRequestPiano context="login" @ready="showLogin" />
    </AppModal>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import IconUser from '@/components/ui/icons/icon-user.svg'
import AppModal from '@/components/ui/modal/AppModal'
import ConsentRequestPiano from '@/components/consent/ConsentRequestPiano'

export default {
  components: {
    ConsentRequestPiano,
    IconUser,
    AppModal,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      isNavOpened: false,
      isConsentDialogOpen: false,
    }
  },
  computed: {
    tinypassActive() {
      return !!this.$config.tinypass
    },
    isLoggedIn() {
      return this.$store.getters['tinypass/isLoggedIn']
    },
  },
  created() {
    this.$nuxt.$on('openedMainNav', this.closeMenu)
  },
  methods: {
    trackingEventsPush() {
      const eventName = 'Login'

      window._mtm = window._mtm || []
      window._mtm.push({ event: 'mat' + eventName })
    },
    showLogin() {
      if (this.$store.getters['consent/hasPianoConsent']) {
        this.isConsentDialogOpen = false
        this.$store.dispatch('tinypass/login')
      } else {
        this.isConsentDialogOpen = true
      }
    },
    toggleMenu() {
      this.isNavOpened = !this.isNavOpened
      if (this.isNavOpened) {
        this.$nuxt.$emit('openedUserNav')
      }
    },
    closeMenu() {
      this.isNavOpened = false
    },
    hideNav() {
      this.isNavOpened = false
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.header-navigation--user {
  position: relative;
}

.header-user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  padding: 10px 20px;
  background-color: $color-white;
  transition-duration: 300ms;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.4);
}

.user-menu-list {
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    padding: 12px 0;
    border-top: 1px solid $color-light-grey;

    &:first-child {
      border-top: none;
    }

    &::before {
      content: none;
    }
  }
}

.user-menu-link {
  color: $color-text;
  font-size: 18px;
  font-weight: 400;
}

.user-menu-fade-enter-active,
.user-menu-fade-leave-active {
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.user-menu-fade-enter,
.user-menu-fade-leave-to {
  opacity: 0;
  transform: translate3d(-6px, 0, 0);
}

.user-menu-fade-leave,
.user-menu-fade-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
