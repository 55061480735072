<template>
  <div class="consent-request">
    <slot>
      <p>
        To access this content, you need to consent for data collection by Die Furche. Die Furche will collect your data
        for the purpose of measurement and personalisation. By loading the content you agree to to Die Furche's
        <a target="_blank" href="https://en.wikipedia.org/wiki/General_Data_Protection_Regulation"> privacy policy </a>.
      </p>
    </slot>
    <button class="consent-request__button" @click="$emit('consented')">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: 'Einwilligung erteilen und Inhalt anzeigen',
    },
  },
}
</script>

<style lang="scss">
.consent-request {
  padding: 20px;
  text-align: center;

  &__button {
    margin-top: 30px;
  }
}
</style>
