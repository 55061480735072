export default function trackPageView({
  currentChannel,
  isPremiumArticle,
  currentPublishDate,
  currentAuthor,
  currentPageClass,
}) {
  if (currentChannel) {
    const eventName = 'Channel'
    window._mtm = window._mtm || []
    window._mtm.push({
      event: 'mat' + eventName,
      channel: currentChannel,
    })
  }
  if (isPremiumArticle) {
    const eventName = 'Premium'

    window._mtm = window._mtm || []
    window._mtm.push({
      event: 'mat' + eventName,
      premium: isPremiumArticle,
    })
  }
  if (currentPublishDate) {
    const eventName = 'Published Date'

    window._mtm = window._mtm || []
    window._mtm.push({
      event: 'mat' + eventName,
      datePublished: currentPublishDate,
    })
  }
  if (currentAuthor) {
    const eventName = 'Author'

    window._mtm = window._mtm || []
    window._mtm.push({
      event: 'mat' + eventName,
      author: currentAuthor,
    })
  }
  if (currentPageClass) {
    const eventName = 'Pageclass'

    window._mtm = window._mtm || []
    window._mtm.push({
      event: 'mat' + eventName,
      pageClass: currentPageClass,
    })
  }
}
